if (!window.App) {
  window.App = {};
}

App.Products = {
  calendar: null,

  initCalendar() {
    const $el = $('.flatpickr');
    if ($el.length > 0) {
      this.calendar = $el.flatpickr($el.data('flatpickr'));
    }
  },

  handleSearchFormSubmit(e, form = null) {
    e.preventDefault();
    form = form ? form : e.target;
    const url = `${form.action}?${$(form).serialize()}`;
    Turbolinks.visit(url);
    return false;
  },

  setInquiryCalendarEvents() {
    if (this.calendar != null) {
      this.calendar.config.onChange.push((selectedDates)=> {
        $('input#inquiry_form_start_date').val(selectedDates[0]);
        $('input#inquiry_form_end_date').val(selectedDates[1]);
        if (selectedDates.length === 2) {
          $("form[target='paypal'] input[name='submit']").removeClass('hidden');
        }
      })
    }
  },

  initCategoriesSelect2() {
    $('.select2-product-categories').select2({
      placeholder: "All categories"
    });
  },

  handleCategoriesChange(e) {
    this.handleSearchFormSubmit(e, e.target.form);
  }
};

$(document).on("submit", "form#product_search", (e) => {
  App.Products.handleSearchFormSubmit(e)
});

$(document).on('change.select2', '.select2-product-categories', (e) => {
  App.Products.handleCategoriesChange(e)
});

$(document).on("turbolinks:load", () => {
  App.Products.initCalendar();
  App.Products.setInquiryCalendarEvents();
  App.Products.initCategoriesSelect2();
});
