import { Controller } from "stimulus";

export default class extends Controller {
  buyNow(e) {
    let stripe = Stripe('pk_test_51H32UjEtbMqYlJGY25tXz1KqU3LN3OcSd9RUZNDOPCFkuG8gXvLkON1YtN1YA1y4JGyGAaJHaVv5yWOykfHbWQ7b00BHgJeVie')
    let token = document.getElementsByName('csrf-token')[0].content
    let productId = e.target.dataset.productId

    $.ajax({
      url: '/payments',
      method: 'POST',
      headers: {
        X_CSRF_Token: token,
        Accept: 'application/json'
      },
      data: {
        product_id: productId
      },
      success: data => {
        return stripe.redirectToCheckout({ sessionId: data.id });
      },
      error: data => {
        console.log('error')
        console.log(data)
      }
    })
  }
}
