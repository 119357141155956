if (!window.App) { window.App = {}; }

App.CookiesBanner = {
  accept() {
    const isSecure = location.protocol === 'https:';
    Cookies.set('cookie_consent', true, { path: '/', expires: 365, secure: isSecure });
    return $('.cookies-banner').remove();
  }
};

$(document).on('click', 'a[data-cookies-accept]', () => App.CookiesBanner.accept());
