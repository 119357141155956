/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Require packages
require('@rails/ujs').start();
require("@rails/activestorage").start();
require('turbolinks').start();
require('trix');
require('@rails/actiontext');
require('jquery');
require('popper.js');
require('bootstrap');
require('bootstrap-datepicker');
require('js-cookie');
require("flatpickr");
require('select2')

// Require assets under vendor/
require('headroom.min')
require('onscreen.min')
require('nouislider.min')
require('argon.min')

require('app/cookies_banner');
require('app/products');


import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

var siteNav = $('.header-global');
var siteHeader = 1;

$(window).scroll(function () {
  //console.log('scroll1');
  //console.log(siteHeader);
  if ($(this).scrollTop() > siteHeader) {
    //console.log('scroll2');
    $('.header-global').addClass('sticky-nav');
  } else {
    //console.log('scroll3');
    $('.header-global').removeClass('sticky-nav');
  }
});

// Smooth scrolling for links
$(document).on('click', 'a[href^="#"]', function (event) {
  event.preventDefault();
  $('html, body').animate({
    scrollTop: $($.attr(this, 'href')).offset().top
  }, 500);
});

// Hubspot fix
$(document).on('turbolinks:before-visit', (e) => {
  let scripts, script;

  // Remove scripts added by hs-scriptloader
  scripts = document.querySelectorAll('script[data-loader="hs-scriptloader"]');
  scripts.forEach((script) => {
    script.remove();
  });

  // Remove Hubspot Analytics
  script = document.getElementById('hs-analytics');
  if (script != undefined) {
    script.remove();
  }
})
