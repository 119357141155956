import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["passwordFieldsTemplate", "passwordFields", "password", "passwordConfirmation", "append"]

  registerUser(e) {
    e.preventDefault()
    if (e.target.checked) {
      this.appendTarget.insertAdjacentHTML("afterend", this.passwordFieldsTemplateTarget.innerHTML)
    } else {
      this.passwordFieldsTarget.remove()
    }
  }

  passwordMatch(e) {
    var validationMessage = "Password confirmation does not match with password."
    if (this.passwordTarget.value == this.passwordConfirmationTarget.value) {
      validationMessage = ""
    }
    this.passwordConfirmationTarget.setCustomValidity(validationMessage)
  }
}
